import { Component, OnInit } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {
 
  selectedFiles?: any = [];
  progressInfos: any[] = [];
  message: string[] = [];
  fileInfos?: Observable<any>;

  myForm: FormGroup;
  email_id;
  recaptcha;
  genid;

  siteKey: string;  // www.xpri.co.in
  /*
  www.xpri.net/
  Site Key: 6Lc3s8AaAAAAAAY2wYnlTJCsztRYr-PJMcAzgdWa
  Secret Key: 6Lc3s8AaAAAAADI_JtQXZ0Zs0z4l9IL5C4cxyjPt
  */
  
  /*
  www.xpri.co.in/
  Site Key: 6Lc70rsaAAAAAORV2GayZUREsj6DgwQtyHOq1ExD
  Secret Key: 6Lc70rsaAAAAAFolDThcNnkrIMfxDrBWfJuN7n-p
  */

  /*
  localhost(127.0.0.1)
  Site Key: 6Lc01bsaAAAAAIYqGchN0PQUf2H6BIk0bouylzkg
  Secret Key: 6Lc01bsaAAAAAHy4653ayN24-7baY9Z9FKuT9SIN
  */
  newFileList;

  constructor(private uploadService: UploadFilesService,public snackBar:MatSnackBar, private fb: FormBuilder) {
    // this.siteKey = '6Lc01bsaAAAAAIYqGchN0PQUf2H6BIk0bouylzkg';  // local
    this.siteKey='6Lc3s8AaAAAAAAY2wYnlTJCsztRYr-PJMcAzgdWa';    // live
  }

  ngOnInit(): void {
    this.fileInfos = this.uploadService.getFiles();

    this.myForm = this.fb.group({
      email_id: ['', Validators.required],
      recaptcha: ['', Validators.required],

    });
  }


  selectFiles(event): void {
    this.message = [];
    this.progressInfos = [];
    // Concating existing array i.e selectedFiles with Array.from(event.target.files)
    this.selectedFiles = this.selectedFiles.concat(Array.from(event.target.files));

    console.log('this.selectedFiles----',this.selectedFiles);
    console.log('this.selectedFiles Length----',this.selectedFiles.length);
    // console.log('file type type----',this.selectedFiles[0].type);
    // for(var i=1; i<=this.selectedFiles.length;i++){
    //   console.log('file type type----',this.selectedFiles[i].type);
    //   if(this.selectedFiles[i].type=='image/jpeg' || this.selectedFiles[i].type=='image/png' || this.selectedFiles[i].type=='application/pdf'  || this.selectedFiles[i].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
        
    //   }
    // }

  }

  removeSelectedFile(index) {
    // Delete the item from fileNames list
    // this.selectedFiles.splice(index, 1);

    // delete file from FileList
    // this.fileList.splice(index, 1);

    this.selectedFiles.splice(index,1);
    console.log('newFileList----files-->> >',this.selectedFiles);

   }
  

  uploadFiles(): void {
    this.message = [];
    this.email_id = this.myForm.value.email_id;
    this.recaptcha = this.myForm.value.recaptcha;
    console.log('event--email--:', this.email_id);
    console.log('event--reCaptcha--:', this.myForm.value.recaptcha);
    // console.log('event----:',event);

    // var date = new Date();
    // this.genid = date.getTime();
    this.genid=generate(6);

    // console.log('genid--->>',this.genid);
		// console.log('generate 6 digit code --->>',generate(6));

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {

        console.log('this.selectedFiles[i] size-- >>',this.selectedFiles[i].size);
        console.log("File type ------>>",this.selectedFiles[i].type);

        if (this.selectedFiles[i].size>=7000000){
          // alert("Your file is should be less than or equal to 2 MB. You can't upload it."+this.selectedFiles[i].name);
          this.snackBar.open('Your file should be less than Or equal to 7 MB', '', {
            duration: 6000
          });
        }else if(this.selectedFiles.length > 5){
          // alert("Maximum file upload is five !");
          this.snackBar.open('You can not upload more than 5 Files.', '', {
            duration: 6000 
          });
        }
         else {
          if(this.selectedFiles[i].type=='image/jpeg' || this.selectedFiles[i].type=='image/png' || this.selectedFiles[i].type=='application/pdf'  || this.selectedFiles[i].type=='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || this.selectedFiles[i].type=='image/bmp' || this.selectedFiles[i].type=='image/jpeg' || this.selectedFiles[i].type=='application/vnd.openxmlformats-officedocument.presentationml.presentation' || this.selectedFiles[i].type=='application/vnd.ms-powerpoint'){
            console.log("true");  
            this.upload(i, this.selectedFiles[i]);
          }else{
            // alert("Please Enter valid file type. i.e jpeg,png,pdf,docx.");
            this.snackBar.open('Please Enter valid file type. i.e jpeg,png,pdf,docx,pptx', '', {
              duration: 6000
            });
          }
          
        }
         
      }
    }
  }

  upload(idx: number, file: File): void {
    this.progressInfos[idx] = { value: 0, fileName: file.name };
    if ((this.recaptcha == '' || this.recaptcha == undefined) || (this.email_id =='' || this.email_id==undefined)) {
    console.log("inside if");
    alert("Please Fill the Complete Data..");
      
    }else{
      console.log("inside else...");
      if (file) {
        this.uploadService.upload(file, this.email_id,this.genid).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
              console.log('event-->>>',event.loaded);
            } else if (event instanceof HttpResponse) {
              const msg = 'Uploaded the file successfully: ' + file.name;
              this.message.push(msg);
              this.fileInfos = this.uploadService.getFiles();
            }
          },
          (err: any) => {
            this.progressInfos[idx].value = 0;
            const msg = 'Could not upload the file: ' + file.name;
            this.message.push(msg);
            this.fileInfos = this.uploadService.getFiles();
          });
      }
    }
  }


}

// function to generate six digit code
function generate(n) {
  var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.   

  if ( n > max ) {
      return generate(max) + generate(n - max);
  }

  max        = Math.pow(10, n+add);
  var min    = max/10; // Math.pow(10, n) basically
  var number = Math.floor( Math.random() * (max - min + 1) ) + min;

  return ("" + number).substring(add); 
}


/*
****************************** reCaptcha Details*************************************
1) npm package
https://www.npmjs.com/package/ngx-captcha
2) google site
https://developers.google.com/recaptcha/intro

*/

