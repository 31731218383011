import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {
  // private baseUrl = 'http://localhost:4200';
  // private baseUrl = 'http://35.154.89.113:8003';
  // private baseUrl = 'http://xpri.net:8003';

  // private baseUrl = 'http://xpri.net:8004';  // dev server
   
  private baseUrl = 'https://xpri.net:8004';  // dev https server 
  

  constructor(private http: HttpClient) { }

  upload(file: File,email_id,genid): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    console.log('genid--->>',genid);

    formData.append('file', file);
    formData.append('email_id', email_id);
    formData.append('genid', genid);


    const req = new HttpRequest('POST', `${this.baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    console.log('req--',req);
    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrl}/files`);
  }
}